import { Stack, Typography } from "@mui/material";
import { blueGrey } from "@mui/material/colors";
import React from "react";

export default function Logo(props: {
	isBlue?: boolean;
	height?: number;
	onlyIcon?: boolean;
}) {
	const { isBlue = false, height = 36, onlyIcon = false } = props;

	return (
		<Stack
			direction="row"
			spacing={1}
			sx={{ height: height, mr: 2, display: "flex" }}
		>
			<img
				style={{ width: "2rem", height: "2rem" }}
				src={`/logo${isBlue ? "_dark" : ""}.svg`}
				alt="yd"
			/>
			{!onlyIcon && (
				<Stack>
					<Stack direction="row" spacing={0.5}>
						<Typography
							component="span"
							sx={{ fontSize: 20, lineHeight: 1.1 }}
						>
							YourData
						</Typography>
						<Typography variant="caption" sx={{ color: "#b3e5fc" }}>
							+
						</Typography>
					</Stack>
					<Typography
						component="span"
						sx={{
							fontSize: 8,
							pl: 0.5,
							mt: -0.5,
							color: blueGrey[200],
						}}
					>
						玉东数据
					</Typography>
				</Stack>
			)}
		</Stack>
	);
}
