import DarkModeIcon from "@mui/icons-material/DarkMode";
import LightModeIcon from "@mui/icons-material/LightMode";
import MenuIcon from "@mui/icons-material/Menu";
import {
	AppBar,
	Box,
	Button,
	IconButton,
	Toolbar,
	useTheme,
} from "@mui/material";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Context } from "../services/AppService";
import Logo from "../ui/Logo";

export default function MyToolbar() {
	const theme = useTheme();
	const { pathname } = useLocation();
	const id = pathname.split("/")[2];
	const context = React.useContext(Context);
	const navigate = useNavigate();

	function findRootId(curId: string = ""): string {
		const curItem = context.category?.find((i) => i._id === curId);
		if (curItem?.pid) {
			return findRootId(curItem.pid);
		} else {
			return curId;
		}
	}
	const rootId = findRootId(id);

	return (
		<AppBar
			position="fixed"
			sx={{ bottom: { xs: 0, sm: "auto" }, top: { xs: "auto", sm: 0 } }}
		>
			<Toolbar>
				<IconButton
					sx={{
						mr: 2,
						display: { xs: "block", md: "none" },
					}}
					color="inherit"
					onClick={() => {
						context.toggleDrawer();
					}}
				>
					<MenuIcon></MenuIcon>
				</IconButton>

				<Box onClick={() => navigate("/")} sx={{ cursor: "pointer" }}>
					<Logo />
				</Box>
				<Box sx={{ display: { xs: "none", sm: "block" } }}>
					{context.category
						?.filter((i) => i.pid === "")
						.map((item, index: number) => (
							<Button
								key={index}
								onClick={() => navigate(`/c/${item._id}`)}
								color={
									rootId === item._id
										? "secondary"
										: "inherit"
								}
								size="large"
							>
								{item.title}
							</Button>
						))}
				</Box>
				<Box flex={{ flexGrow: 1 }}></Box>
				<IconButton
					sx={{ ml: 1 }}
					onClick={() => context.toggleColorMode()}
					color="inherit"
				>
					{theme.palette.mode === "dark" ? (
						<LightModeIcon />
					) : (
						<DarkModeIcon />
					)}
				</IconButton>
			</Toolbar>
		</AppBar>
	);
}
