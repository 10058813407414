import { cyan } from "@mui/material/colors";
import indigo from "@mui/material/colors/indigo";
import CssBaseline from "@mui/material/CssBaseline";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import axios from "axios";
import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./home/Home";
import PageList from "./home/Page";
import { IcmsCategory } from "./interface";
import { Context } from "./services/AppService";
import MyToolbar from "./ui/MyToolbar";

export default function App() {
	const [isDark, setIsDark] = React.useState<boolean>(true);
	const [drawerOpen, setDrawerOpen] = React.useState<boolean>(false);
	const [category, setCategory] = React.useState<IcmsCategory[]>();
	const ucontext = React.useContext(Context);

	const context = React.useMemo(
		() => ({
			...ucontext,
			drawerOpen,
			category,
			toggleColorMode: () => {
				setIsDark((prevMode) => !prevMode);
			},
			toggleDrawer: () => {
				setDrawerOpen((prevMode) => !prevMode);
			},
		}),
		[drawerOpen, category, ucontext]
	);

	React.useEffect(() => {
		axios
			.post("opencms/category", { topicId: ucontext.topicId })
			.then((r) => {
				const _r: IcmsCategory[] = r.data || [];
				const f = (pid: string = "", paths: string[] = []) => {
					const a = _r.filter((i) => {
						const is = i.pid === pid;
						if (is) {
							i.paths = [...paths, i._id];
							i.children = f(i._id, i.paths);
						}
						return is;
					});
					return a?.length ? a : undefined;
				};
				f();
				setCategory(_r);
			});
	}, [ucontext.topicId]);

	const theme = React.useMemo(() => {
		return createTheme({
			palette: {
				mode: isDark ? "dark" : "light",
				primary: { main: indigo[500] },
				secondary: { main: cyan[200] },
			},
		});
	}, [isDark]);

	return (
		<Context.Provider value={context}>
			<ThemeProvider theme={theme}>
				{category ? (
					<BrowserRouter>
						<CssBaseline />
						<MyToolbar />
						<Routes>
							<Route path="*" element={<Home />} />
							<Route path="c/:id" element={<PageList />} />
							<Route path="c/:id/:docId" element={<PageList />} />
						</Routes>
					</BrowserRouter>
				) : null}
			</ThemeProvider>
		</Context.Provider>
	);
}
