import AppShortcutIcon from "@mui/icons-material/AppShortcut";
import ConnectedTvIcon from "@mui/icons-material/ConnectedTv";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import TouchAppIcon from "@mui/icons-material/TouchApp";
import {
	Avatar,
	Box,
	Card,
	CardContent,
	CardHeader,
	Container,
	Grid,
	Link,
	Stack,
} from "@mui/material";
import { blue, lightGreen, pink, red, teal } from "@mui/material/colors";
import { useTheme } from "@mui/material/styles";
import anime from "animejs";
import axios from "axios";
import React from "react";
import { useNavigate } from "react-router-dom";
const productsList = [
	{
		title: "App开发",
		icon: <AppShortcutIcon />,
		color: teal[500],
		content: "Android、iOS、鸿蒙 手机及平板应用开发",
		link: "6356466f3fe39208b234c6ed",
	},
	{
		title: "小程序",
		icon: <TouchAppIcon />,
		color: blue[500],
		content: "微信、支付宝小程序，公众号应用开发",
		link: "635647c93fe39208b234c70e",
	},
	{
		title: "综合平台部署",
		icon: <ConnectedTvIcon />,
		color: lightGreen[500],
		content: "私有云平台开发/部署，跨平台终端",
		link: "6356603a7326fefa4312d2f3",
	},
	{
		title: "教育行业",
		icon: <GroupAddIcon />,
		color: red[500],
		content: "教育行业解决方案，试题，教育测量统计",
		link: "6357604cc53ce5b329e2ca6b",
	},
];
/**
 * 首页
 * @returns
 */
export default function Home() {
	const navigate = useNavigate();
	const theme = useTheme();

	//======================================================动画

	const logoAnime = () => {
		anime({
			targets: "#svg-container .st2",
			strokeDashoffset: [anime.setDashoffset],
			easing: "easeInOutSine",
			duration: 1000,
			delay: function (el, i) {
				return i * 200;
			},
			direction: "alternate",
			// update: function (anim) {
			// 	if (anim.progress === 0) {
			// 		nameenAnime();
			// 	}
			// }
		});
	};

	function nameenAnime() {
		anime({
			targets: ".ani-nameengrid .el",
			opacity: 1,
			scale: [0, 1],
			delay: anime.stagger(100, { grid: [14, 5], from: "first" }),
		});
		setTimeout(() => {
			nameAnime();
		}, 300);
	}

	function nameAnime() {
		anime({
			targets: ".ani-namegrid .el",
			opacity: [0, 1],
			translateX: [-100, 0],
			scale: [{ value: [0, 1], easing: "easeInOutQuad", duration: 500 }],
			delay: anime.stagger(50, { grid: [14, 5] }),
			update: (ani) => {
				if (ani.progress === 100) {
					labelAnime();
				}
			},
		});
	}

	let aniIsok = false;

	function labelAnime() {
		anime({
			targets: ".ani-label",
			opacity: [0.3, 1.0],
			translateX: [100, 0],
			duration: 1200,
		});
		anime({
			targets: ".ani-serviceCard",
			opacity: [0.3, 0.9],
			translateY: [200, 0],
			delay: anime.stagger(100, { grid: [14, 5] }),
			update: (ani) => {
				aniIsok = ani.progress === 100;
			},
		});
	}
	//======================================================

	React.useEffect(() => {
		axios
			.get("/logo_dark.svg")
			.then((res: { data: string }) => {
				let r = res.data;
				if (theme.palette.mode === "light") {
					r = r.replaceAll("#66A5DA", pink[300]);
				}
				const a = document.getElementById("svg-container");
				if (a) {
					a.innerHTML = r || "";
					logoAnime();
					nameenAnime();
				}
			})
			.catch(() => { });
	});
	//======================================================Elment
	return (
		<Container
			sx={{
				pt: 10,
				pb: 10,
				cursor: "pointer",
			}}
		>
			<Box
				sx={{ height: "50vh", display: "flex" }}
				flex={{
					justifyContent: "center",
					alignItems: "center",
					flexDirection: "column",
				}}
				onClick={() => {
					navigate("/c/635606483fe39208b234c6e8");
				}}
			>
				<Box id="svg-container" sx={{ width: 200 }}></Box>
				<Stack
					direction="row"
					className="ani-nameengrid"
					sx={{
						color: "#757575",
						lineHeight: 1,
						alignItems: "baseline",
						letterSpacing: 2,
					}}
				>
					{"YourData.plus".split("").map((i, index) => (
						<Box
							key={index}
							className="el"
							sx={{ opacity: 0, fontSize: index < 8 ? 60 : 30 }}
						>
							{i}
						</Box>
					))}
				</Stack>
				<Stack
					direction="row"
					className="ani-namegrid"
					sx={{ fontSize: 60, color: "#3949ab" }}
				>
					{`玉东数据 ${Array(0).fill("/").join("")}`
						.split("")
						.map((i, index) => (
							<Box key={index} className="el" sx={{ opacity: 0 }}>
								{i}
							</Box>
						))}
				</Stack>
			</Box>
			<Box
				className="ani-label"
				color="text.disabled"
				sx={{
					letterSpacing: "6px",
					mt: 6,
					textAlign: "center",
					opacity: 0,
				}}
				onClick={() => {
					navigate("c/635606483fe39208b234c6e8");
				}}
			>
				发现数据价值 技术驱动未来
			</Box>
			<Grid container sx={{ mt: 6 }} color="text.secondary">
				{productsList.map((item, index) => (
					<Grid
						item
						key={index}
						xs={6}
						sx={{ p: 2 }}
						onClick={() => {
							navigate("c/" + item.link);
						}}
					>
						<Card
							className="ani-serviceCard"
							sx={{
								bgcolor: item.color,
								height: 1,
								opacity: 0.01,
							}}
							onMouseEnter={(e) => {
								aniIsok &&
									anime({
										targets: e.currentTarget,
										scale: [1, 1.2],
										opacity: 1,
									});
							}}
							onMouseLeave={(e) => {
								aniIsok &&
									anime({
										targets: e.currentTarget,
										scale: 1,
										opacity: 0.9,
										translateY: 0,
									});
							}}
						>
							<CardHeader
								title={item.title}
								avatar={
									<Avatar
										sx={{ bgcolor: "rgba(100,100,150,.3)" }}
									>
										{item.icon}
									</Avatar>
								}
							></CardHeader>
							<CardContent>{item.content}</CardContent>
						</Card>
					</Grid>
				))}
			</Grid>
			<Box
				color="text.disabled"
				sx={{ letterSpacing: "2px", mt: 6, textAlign: "center" }}
			>
				甘肃玉东数据有限公司 版权所有
				<Link href="https://beian.miit.gov.cn" target="_blank" sx={{ ml: 2 }}>
					陇ICP备2021001826号
				</Link>
			</Box>
		</Container>
	);
}
