import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import { Box } from "@mui/system";

export default function Footer() {
	return (
		<Container>
			<Divider />
			<Box
				sx={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					height: 100,
				}}
			>
				玉东数据 版权所有
			</Box>
		</Container>
	);
}
