import { Alert, Button } from "@mui/material";
import Loading from "./Loading";

export default function DataCommon(props: {
	data?: any;
	nodataLabel?: string;
	reloadFun?: any;
	error?: string;
}) {
	const { data, nodataLabel, reloadFun, error } = props;
	const res = {
		loading: () => <Loading variant="table" />,
		error: () => (
			<Alert
				sx={{ m: 2 }}
				color="error"
				action={
					<Button color="inherit" size="small" onClick={reloadFun}>
						重试
					</Button>
				}
			>
				{error}
			</Alert>
		),
		nodata: () => (
			<Alert
				sx={{ m: 2 }}
				color="info"
				action={
					<Button color="inherit" size="small" onClick={reloadFun}>
						刷新
					</Button>
				}
			>
				{nodataLabel || "没有数据"}
			</Alert>
		),
	};

	if (!error && !data) {
		return res.loading();
	} else if (error) {
		return res.error();
	} else if (data && data.length === 0) {
		return res.nodata();
	} else {
		return null;
	}
}
