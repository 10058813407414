import { List, ListItem, ListItemButton, Stack } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { IcmsCategory } from "../interface";
import { Context } from "../services/AppService";

export default function Side(props: { id: string }) {
	const { id } = props;
	const navigate = useNavigate();
	const { drawerOpen, toggleDrawer, category } = React.useContext(Context);
	const [curCate, setCurCate] = React.useState<IcmsCategory>();

	React.useEffect(() => {
		setCurCate(category?.find((i) => i._id === id));
	}, [id, category]);

	const sideSx = {
		display: { xs: drawerOpen ? "block" : "none", sm: "block" },
		pt: 8,
		position: "fixed",
		width: 240,
		height: "100vh",
		overflow: "auto",
		borderRight: "1px solid #eeeeee50",
	};

	function itemSx(deep: number, i: IcmsCategory) {
		return {
			ml: 2 * deep,
			display: {
				sm: deep === 0 && i.pid === "" ? "none" : "",
			},
		};
	}

	function handleNav(i: IcmsCategory) {
		toggleDrawer();
		navigate(`/c/${i._id}`);
	}

	function listItem(cate: IcmsCategory[], deep: number = 0) {
		return (
			<List>
				{cate.map((i, index) => {
					const active = curCate?.paths?.includes(i._id);
					return (
						<React.Fragment key={index}>
							<ListItem sx={itemSx(deep, i)} dense>
								<ListItemButton
									selected={active}
									onClick={() => handleNav(i)}
								>
									{i.title}
								</ListItemButton>
							</ListItem>
							{i.children &&
								active &&
								listItem(i.children, deep + 1)}
						</React.Fragment>
					);
				})}
			</List>
		);
	}

	return (
		<Stack sx={sideSx}>
			{category && listItem(category.filter((i) => i.pid === ""))}
		</Stack>
	);
}
