import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import { Fragment } from "react";

export default function Loading(props: {
	variant?: "wave" | "circular" | "card" | "table";
}) {
	const { variant } = props;
	const view = {
		wave: <Skeleton animation="wave" />,
		circular: <CircularProgress />,
		card: (
			<Fragment>
				<Skeleton variant="text" />
				<Skeleton variant="circular" width={40} height={40} />
				<Skeleton variant="rectangular" width={210} height={118} />
			</Fragment>
		),
		table: (
			<Fragment>
				<Stack spacing={3} sx={{ marginTop: 5 }}>
					{Array(3)
						.fill("key")
						.map((i, index) => (
							<Skeleton
								key={i + index}
								variant="rectangular"
								height={40}
								width="90%"
							/>
						))}
				</Stack>
			</Fragment>
		),
		default: (
			<Fragment>
				<Stack spacing={2}>
					<Skeleton variant="rectangular" height={20} width="40%" />
					<Skeleton variant="rectangular" height={60} />
					<Skeleton variant="rectangular" height={200} width="80%" />
				</Stack>
			</Fragment>
		),
	};

	return view[variant || "default"] || view["default"];
}
