import { Box, Link, Typography } from "@mui/material";
import React from "react";

import { APIHOST, Context } from "../services/AppService";
import Markdown from "../ui/Markdown";
export interface BodyItem {
	type: "p" | "t" | "i" | "a" | "v" | "md";
	value: string;
}
export default function DisplayBody(props: { body?: BodyItem[] }) {
	const context = React.useContext(Context);

	const { body } = props;
	return (
		<React.Fragment>
			{body?.map((i, index) => {
				if (i.type === "t") {
					return (
						<Typography
							key={index}
							variant="body2"
							sx={{ textAlign: "center", mb: 2 }}
						>
							{i.value}
						</Typography>
					);
				} else if (i.type === "p") {
					return (
						<Typography
							key={index}
							variant="body1"
							sx={{
								mt: 2,
								mb: 2,
								textIndent: "2rem",
								lineHeight: 2,
								textAlign: "justify",
							}}
						>
							{i.value}
						</Typography>
					);
				} else if (i.type === "md") {
					return <Markdown srcData={i.value} key={index} />;
				} else if (i.type === "i") {
					return (
						<Box
							key={index}
							sx={{ textAlign: "center", mt: 2, mb: 2 }}
						>
							<img
								style={{
									minWidth: "15rem",
									background: "#9e9e9e",
									maxWidth: "100%",
									minHeight: "6rem",
									maxHeight: "100%",
								}}
								src={APIHOST + "upload/cms/" + context.topicId + '/' + i.value}
								alt=""
							/>
						</Box>
					);
				} else if (i.type === "v") {
					return (
						<Box sx={{ textAlign: "center" }}>
							<video
								controls={true}
								style={{
									minWidth: "15rem",
									background: "#9e9e9e",
									maxWidth: "100%",
									minHeight: "6rem",
									maxHeight: "100%",
								}}
								src={APIHOST + "upload/cms/" + context.topicId + '/' + i.value}
							/>
						</Box>
					);
				} else if (i.type === "a") {
					return (
						<Box sx={{ textAlign: "center" }}>
							<audio
								controls={true}
								style={{
									minWidth: "15rem",
									maxWidth: "100%",
									minHeight: "1rem",
								}}
								src={APIHOST + "upload/cms/" + context.topicId + '/' + i.value}
							/>
						</Box>
					);
				} else if (i.type === "up") {
					return (
						<Link
							target="_blank"
							download="下载文件"
							href={APIHOST + "upload/cms/" + context.topicId + '/' + i.value}
						>
							{APIHOST + "upload/cms/" + context.topicId + '/' + i.value}
						</Link>
					);
				} else {
					return <Box />;
				}
			})}
		</React.Fragment>
	);
}
