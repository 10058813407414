import axios from 'axios';
import React from 'react';
import { IcmsCategory } from '../interface';
export const APIHOST = window.location.host.includes("localhost:")
	? "http://localhost:3050/"
	: "https://api.yourdata.plus/";

// 拦截  axios request
axios.interceptors.request.use(config => {
	config.baseURL = APIHOST
	config.headers = {
		Accept: 'application/json'
	}
	return config;
});
axios.interceptors.response.use(response => {
	try {
		if (typeof response.data === 'string' &&
			response.config.url?.split('.').slice(-1)[0] !== 'svg'
		) {
			response.data = JSON.parse(response.data)
		};
		return response;
	} catch (e) {
		return {}
	}
}, err => {
	return {};
})

// 主题模式
export const ColorModeContext = React.createContext({
})

export const Context = React.createContext<{
	toggleColorMode: () => void;
	toggleDrawer: () => void;
	drawerOpen: boolean;
	topicId: string
	category?: IcmsCategory[];
}>({
	toggleColorMode: () => { },
	toggleDrawer: () => { },
	drawerOpen: false,
	topicId: "6354fbab357ee1850ad8989f"
})
