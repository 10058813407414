import { Article } from "@mui/icons-material";
import {
  Breadcrumbs,
  Button,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography
} from "@mui/material";
import { Container } from "@mui/system";
import axios from "axios";
import moment from "moment";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Ipage } from "../interface";
import { Context } from "../services/AppService";
import DataCommon from "../ui/DataCommon";
import DataPage from "../ui/DataPage";
import DisplayBody from "../ui/DisplayBody";
import Footer from "../ui/Footer";
import Side from "./Side";

export default function PageList() {
  const {
    drawerOpen,
    topicId,
    category,
  } = React.useContext(Context);
  const navigate = useNavigate();
  const { id, docId } = useParams();
  const [query, setQuery] = React.useState({ limit: 10, skip: 0 });
  const [data, setData] = React.useState<{ count: number; data: Ipage[] }>();
  const [doc, setDoc] = React.useState<Ipage>();

  React.useEffect(find, [id, docId, category, query, topicId, navigate]);

  function find() {
    !docId
      ? axios
        .post("opencms/page", { topicId, paths: id, ...query })
        .then((r) => {
          if (
            r.data &&
            r.data.count === 1 &&
            r.data.data[0]?._id
          ) {
            navigate(
              `/c/${r.data.data[0].paths.at(-1)}/${r.data.data[0]._id
              }`
            );
          } else {
            setData(r.data);
            setDoc(undefined);
          }
          return () => { };
        })
      : axios.post("opencms/page", { topicId, _id: docId }).then((r) => {
        setDoc(r.data);
        setData(undefined);
      });
  }

  const showDoc = (
    <React.Fragment>
      <Breadcrumbs separator="›">
        {doc?.paths.map((i, index) => (
          <Button
            key={index}
            onClick={() => {
              navigate(`/c/${i}`);
            }}
          >
            {category?.find((x) => x._id === i)?.title}
          </Button>
        ))}
      </Breadcrumbs>
      <Typography fontSize={40} variant="h1" sx={{ mt: 4, mb: 2 }} textAlign={"center"}>
        {doc?.title}
      </Typography>
      <Typography component={"p"} sx={{ mb: 4 }} variant="caption" textAlign={"center"}>
        {moment(doc?.time).format("y-MM-DD")}
      </Typography>
      <DisplayBody body={doc?.body || []}></DisplayBody>
      <Stack height={100}></Stack>
    </React.Fragment>
  );

  const showList = (
    <React.Fragment>
      <List dense>
        {data?.data?.map((i, index) => (
          <ListItem
            key={index}
            onClick={() => {
              navigate(`/c/${i.paths.at(-1)}/${i._id}`);
            }}
          >
            <ListItemButton>
              <ListItemIcon>
                <Article />
              </ListItemIcon>
              <ListItemText
                primary={i.title}
                secondary={moment(i.time).format("yyyy-MM-DD")}
              ></ListItemText>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <DataCommon data={data?.data} />
      {data && data.count > 0 && (
        <DataPage
          count={data?.count}
          query={query}
          setQuery={setQuery}
        />
      )}
    </React.Fragment>
  );

  const contentSx = {
    pt: 8,
    ml: {
      xs: drawerOpen ? "250px" : 2,
      sm: "250px",
    },
    minHeight: "90vh",
    mr: 2,
  };

  return (
    <Stack sx={{ mb: 2 }}>
      {id && <Side id={id!}></Side>}
      <Stack spacing={2} sx={contentSx}>
        <Container>
          {doc && showDoc}
          {data && showList}
        </Container>
        <Footer />
      </Stack>
    </Stack>
  );
}
